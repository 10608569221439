<template>
  <div class="container-fluid">
    <div class="row bg-login-submit">
      <a class="btn login-back btn--dark" href="#" @click="gotoQuickSend">
        <i class="fa fa-chevron-left"></i>
      </a>
      <div class="sup-container">
        <div class="card"></div>
        <div class="card">
          <h1 class="title">Start Will</h1>

          <div class="input-container">
            <input
              v-model="register.email"
              type="email"
              class="input-standard-grey allow-submit"
              @keyup.enter="registerAccount"
              id="username"
              placeholder="Client Email"
            />

            <label for="username">Client Email</label>
            <span class="fa fa-user input-icon"></span>
            <ErrorPop
              v-show="loginErrorEmail"
              :message="loginErrorEmail"
              @clearError="registerErrors.email = null"
              :displayClose="true"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="register.code"
              type="text"
              class="input-standard-grey allow-submit"
              @keyup.enter="registerAccount"
              id="password"
              placeholder="Broker Access Code"
            />
            <label for="password">Broker Access Code</label>

            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="loginErrorCode"
              :message="loginErrorCode"
              @clearError="registerErrors.code = null"
              :displayClose="true"
            ></ErrorPop>
          </div>
          <div v-if="handoverUser" class="input-container banner mb30">
            <p
              v-if="
                handoverPartner &&
                handoverPartner.name &&
                handoverUser &&
                handoverUser.name
              "
            >
              Create Mirror will for
              {{ handoverPartner.name }} based upon
              {{ handoverUser.name || handoverUser.email }}'s will.
            </p>
            <p v-else>
              Create a Mirror will based upon
              {{ handoverUser.name || handoverUser.email }}'s will.
            </p>
            <p
              class="link font-primary font-bold"
              style="text-align: right; width: 100%"
              @click="clearMirror"
            >
              Cancel Mirror
            </p>
          </div>

          <div class="input-container align-center">
            <button
              @click="registerAccount"
              class="btn btn-medium btn-arrow btn--secondary waves-effect waves-dark mb60"
            >
              <span>Create account</span>
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="sup-footer">
        <p>
          <a href="#" @click="gotoBrokerAccount">Forgotten Access Code</a>
        </p>
      </div>
    </div>
    <BaseModalSmall
      @close="show.handoverUser = false"
      v-show="show.handoverUser && handoverUser"
      :show="show.handoverUser && handoverUser"
      v-bind:isValidated="true"
      split-buttons="true"
      no-slide="true"
      @confirm="show.handoverUser = false"
      @cancel="clearMirror"
    >
      <template v-slot:header>Create Mirror Will?</template>
      <template v-slot:body>
        <p
          v-if="
            handoverPartner &&
            handoverPartner.name &&
            handoverUser &&
            handoverUser.name
          "
        >
          Create Mirror will for {{ handoverPartner.name }} based upon
          {{ handoverUser.name || handoverUser.email }}'s will.
        </p>
        <p v-else>
          Create a Mirror will based upon
          {{ handoverUser.name || handoverUser.email }}'s will.
        </p>
      </template>
      <template v-slot:button> Proceed </template>
    </BaseModalSmall>
  </div>
</template>

<script>
import ErrorPop from '../../../common/ui/ErrorPop'
import { http } from '@/services'

import BaseModalSmall from '../../../common/ui/BaseModalSmall'

export default {
  name: 'LoginRegister',
  components: {
    ErrorPop,
    BaseModalSmall
  },
  watch: {
    loginError: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.detail) this.$notification.error(value.detail)
      }
    },
    handoverUser: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value) {
          this.register.mirror_will_user = value.email
        }
      }
    },
    handoverPartner: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.email) this.register.email = value.email
      }
    }
  },
  created() {
    this.$store.dispatch('logout', true)
  },
  mounted() {
    if (localStorage.getItem('handoverUser'))
      this.handoverUser = JSON.parse(localStorage.getItem('handoverUser'))
    if (localStorage.getItem('handoverPartner'))
      this.handoverPartner = JSON.parse(localStorage.getItem('handoverPartner'))
  },
  data() {
    return {
      register: {
        email: null,
        code: null,
        mirror_will_user: null
      },
      login: {
        email: null,
        password: null
      },
      handoverUser: null,
      handoverPartner: null,
      registerErrors: null,
      show: {
        handoverUser: true
      }
    }
  },
  computed: {
    validateLogin() {
      return !!(this.login.email && this.login.password)
    },
    loginErrorEmail() {
      if (this.registerErrors && this.registerErrors.email)
        return this.registerErrors.email[0]
      return null
    },
    loginErrorCode() {
      if (this.registerErrors && this.registerErrors.code)
        return this.registerErrors.code[0]
      return null
    }
  },
  methods: {
    registerAccount() {
      http
        .post('/broker/api/v2/broker_create_user_account_quick', this.register)
        .then((response) => {
          this.$store.dispatch('_setTokens', response.data)
          if (response.data.new_account) {
            this.$notification.success('New Account Created')
          } else {
            this.$notification.success('Welcome back')
          }
          this.$store.commit('removeHandover')
        })
        .catch((error) => {
          console.log(error)
          this.registerErrors = error.response.data
        })
    },
    changeLoginPasswordType() {
      if (this.login.passwordType === 'text') {
        this.login.passwordType = 'password'
      } else {
        this.login.passwordType = 'text'
      }
    },
    changeRegisterPasswordType() {
      if (this.register.passwordType === 'text') {
        this.register.passwordType = 'password'
      } else {
        this.register.passwordType = 'text'
      }
    },
    gotoQuickSend() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = 'https://broker.mylastwill.co.uk/'
      } else {
        window.location.href = 'http://127.0.0.1:8000/broker_portal#/'
      }
    },
    gotoBrokerAccount() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = ' https://broker.mylastwill.co.uk/'
      } else {
        window.location.href = 'http://127.0.0.1:8000/broker'
      }
    },
    clearMirror() {
      localStorage.removeItem('handoverUser')
      localStorage.removeItem('handoverPartner')
      this.handoverPartner = null
      this.handoverUser = null
      // this.$store.commit('removeHandover')
      this.register.mirror_will_user = null
      this.$store.commit('clearInitialise')
    }
  }
}
</script>

<style scoped>
.title {
  margin-bottom: 40px;
}
.banner {
  background-color: #e56781;
  padding: 30px 30px 10px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.banner p {
  color: white;
}
</style>
